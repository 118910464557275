<template>
	<div class="container flex f-column f-align">
		<span class="font60 black bold">{{ placeholder }}</span>
		<input
			ref="code"
			@click="clickHandle($event, 'code')"
			class="code-input"
			:placeholder="'请' + placeholder"
			v-model="formData.code"
			:type="inputType"
			:maxlength="max"
		/>
		<div class="key-board flex f-column f-align f-justifyB">
			<key-board
				ref="keyBoard"
				keyType="num"
				:max="max"
				@change="boardChange"
				@complete="nextHandle"
			></key-board>
			<div class="next-btn" @click="nextHandle">确定</div>
		</div>
	</div>
</template>
<script>
import keyBoard from '../components/keyBoard.vue'
export default {
	data() {
		return {
			formData: {
				code: ''
			},
			placeholder: '',
			inputType: 'text',
			max: 6
		}
	},
	components: {
		keyBoard
	},
	mounted() {
		if (this.$route.query.type == 'xie') {
			this.$emit('change', '/shoesCode?id=' + this.$route.query.id)
			this.placeholder = '输入衣扣码'
			this.max = 100
		} else if (this.$route.query.type == 'userqu') {
			this.$emit('change', '')
			this.placeholder = '输入取件码'
			this.max = 6
		} else {
			this.$emit('change', '')
			this.max = 8
			this.placeholder = '密码验证'
			this.inputType = 'password'
		}
		setTimeout(() => {
			this.$refs.code.focus()
			this.$refs.keyBoard.focusIndex = 0
			this.$refs.keyBoard.commonStr = this.formData.code
		}, 300)
	},
	methods: {
		getpwd() {
			let d = new Date()
			let year = d.getFullYear()
			let month =
				d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
			let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
			return year + '' + month + '' + day
		},
		clickHandle(event, type) {
			this.$refs.keyBoard.focusIndex = event.target.selectionStart
			this.$refs.keyBoard.commonStr = this.formData[type]
		},
		boardChange(str) {
			this.formData['code'] = str
		},
		nextHandle() {
			if (!this.formData.code) {
				this.$toast({
					message: '请' + this.placeholder,
					duration: 3000
				})
				return
			}

			if (this.$route.query.type == 'sys') {
				if (this.formData.code == this.getpwd()) {
					this.$router.replace('./sysSet?net=' + this.$route.query.net)
				} else {
					this.$toast('密码错误')
				}
				return
			}

			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				target: '.app-box'
			})
			let type = localStorage.getItem('type')

			if (type == 'xy-qu') {
				this.$post('/Order/adminGetOut', {
					shoeBuckleNumber: this.formData.code,
					orderDetailId: this.$route.query.id,
					adminId: localStorage.getItem('adminId')
				})
					.then((res) => {
						loading.close()
						this.$toast({
							message: res.msg,
							duration: 3000
						})
						if (res.code == 200) {
							setTimeout(() => {
								this.$router.go(-2)
							}, 500)
						} else {
							setTimeout(() => {
								this.$refs.code.focus()
								this.$refs.keyBoard.focusIndex = 0
								this.$refs.keyBoard.commonStr = ''
								this.formData.code = ''
							}, 300)
						}
					})
					.catch(() => {
						loading.close()
					})
			} else if (type == 'user-qu') {
				this.$post('/wxPay/payUrl', {
					takeOutCode: this.formData.code,
					gzbh: localStorage.getItem('gzbh')
				})
					.then((res) => {
						loading.close()
						this.$toast({
							message: res.msg,
							duration: 3000
						})
						if (res.code == 200) {
							if (res.codeUrl) {
								localStorage.setItem('xmbh', res.xmbh)
								localStorage.setItem('openCmd', res.openCmd)
								localStorage.setItem('portName', res.portName)
								this.$router.push(
									'./payMoney?code=' +
										res.codeUrl +
										'&price=' +
										res.price +
										'&outTradeNo=' +
										res.outTradeNo
								)
							} else {
								localStorage.setItem('xmbh', res.xmbh)
								localStorage.setItem('openCmd', res.openCmd)
								localStorage.setItem('portName', res.portName)
								this.$router.push('./boxDoorOpen')
							}
						} else {
							setTimeout(() => {
								this.$refs.code.focus()
								this.$refs.keyBoard.focusIndex = 0
								this.$refs.keyBoard.commonStr = ''
								this.formData.code = ''
							}, 300)
						}
					})
					.catch(() => {
						loading.close()
					})
			} else {
				this.$post('/Order/adminDeposit', {
					shoeBuckleNumber: this.formData.code,
					gzbh: localStorage.getItem('gzbh'),
					adminId: localStorage.getItem('adminId')
				})
					.then((res) => {
						loading.close()
						this.$toast({
							message: res.msg,
							duration: 3000
						})
						if (res.code == 200) {
							localStorage.setItem('xmbh', res.xmbh)
							localStorage.setItem('openCmd', res.openCmd)
							localStorage.setItem('portName', res.portName)
							this.$router.push('./boxDoorOpen')
						} else {
							setTimeout(() => {
								this.$refs.code.focus()
								this.$refs.keyBoard.focusIndex = 0
								this.$refs.keyBoard.commonStr = ''
								this.formData.code = ''
							}, 300)
						}
					})
					.catch(() => {
						loading.close()
					})
			}
		}
	}
}
</script>
<style scoped>
.container {
	padding-top: 90px;
}

.code-input {
	width: 350px;
	height: 120px;
	border: 1px solid #674b37;
	border-radius: 10px;
	background: rgba(255, 239, 228, 1);
	margin-top: 100px;
	font-size: 30px;
	color: #000000;
	margin-bottom: 80px;
	box-sizing: border-box;
	text-align: center;
}

.next-btn {
	width: 350px;
	height: 120px;
	background: rgba(64, 130, 253, 1);
	border-radius: 10px;
	line-height: 120px;
	text-align: center;
	font-size: 40px;
	color: #ffffff;
}

.key-board {
	height: 296px;
	background: #cccccc;
	width: 100%;
	padding: 36px 0 20px;
	border-radius: 0px 0px 30px 30px;
}
</style>
